<template>
  <div class="col team_category_wrapper">
    <card>
      <div class="name_wrapper_task">
        <span class="d-block part_1"><i class="fa fa-tasks "></i></span>
        <span class="d-block part_2">{{ categoryForTaskName.name }}</span>
      </div>
      <!-- Start Server Table For All Categories Large Screen -->
      <server-table
        class="category_for_large"
        :count="categoryDetailsCount"
        :DataItems="categoryDetailsTasks"
        :filter="filter"
        @Refresh="get_category_details"
        @reset="filter = {}"
        :hidden_export_import="true"
        :title="`${localization('Task Categories')}`"
        :action="false"
      >
        <!-- :actions="false" -->
        <template #columns>
          <el-table-column prop="name" :label="`${localization('Name')}`">
            <template slot-scope="scope">
              <div
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
              >
                {{ scope.row.name }}
              </div>
            </template></el-table-column
          >

          <el-table-column
            prop="topic_name"
            :label="`${localization('Topic')}`"
            min-width="150px"
          >
            <template slot-scope="scope">
              <div
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-word;
                width: 100%;
              "
              >
                <span>{{ scope.row.topic_name }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- // Actions  -->
          <el-table-column prop="" label="" min-width="100">
            <template slot-scope="scope">
              <div class="td_actions actions_wrapper">
                <a
                  @click="getTaskDetails(scope.row)"
                  v-tooltip.top-center="`${localization('Show task')}`"
                  class="actions-icons add"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <!-- <a
                  @click="open_add_task_dialog(2, scope.row)"
                  v-tooltip.top-center="`${localization('Edit task')}`"
                  class="  actions-icons edit"
                >
                  <i class="fa fa-edit"></i
                ></a> -->

                <!-- <a
                  @click="deleteTask(scope.row.id)"
                  v-tooltip.top-center="`${localization('Delete task')}`"
                  class=" actions-icons delete"
                >
                  <i class="fa fa-times"></i
                ></a> -->
              </div>
            </template>
          </el-table-column>
        </template>

        <!-- Add Category  -->
        <!-- <template #action>
          <div type="info" class="ml-2" @click="open_add_task_dialog(1, {})">
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Task") }}
          </div>
        </template> -->
      </server-table>
      <!-- admin_permission="admin_questions"
        edit_permission="add_questions" -->
      <!-- End Server Table For All Categories Large Screen  -->

      <!-- Start Server Table For All Categories Mobile Screen -->
      <server-table
        class="category_for_mobile"
        :count="categoryDetailsCount"
        :DataItems="categoryDetailsTasks"
        :filter="filter"
        @Refresh="get_category_details"
        @reset="filter = {}"
        :hidden_export_import="true"
        :title="`${localization('Task Categories')}`"
        :action="false"
      >
        <template #columns>
          <el-table-column prop="name" :label="`${localization('Name')}`">
            <template slot-scope="scope">
              <div
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
              >
                {{ scope.row.name }}
              </div>
            </template></el-table-column
          >
          <el-table-column
            prop="topic_name"
            :label="`${localization('Topic')}`"
          ></el-table-column>

          <!-- // Actions  -->
          <el-table-column prop="" label="_" min-width="100">
            <template slot-scope="scope">
              <div class="td_actions actions_wrapper">
                <a
                  @click="getTaskDetails(scope.row)"
                  v-tooltip.top-center="`${localization('Show task')}`"
                  class="actions-icons add"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <!-- <a
                  @click="open_add_task_dialog(2, scope.row)"
                  v-tooltip.top-center="`${localization('Edit task')}`"
                  class="  actions-icons edit"
                >
                  <i class="fa fa-edit"></i
                ></a> -->

                <!-- <a
                  @click="deleteTask(scope.row.id)"
                  v-tooltip.top-center="`${localization('Delete task')}`"
                  class=" actions-icons delete"
                >
                  <i class="fa fa-times"></i
                ></a> -->
              </div>
            </template>
          </el-table-column>
        </template>

        <!-- Add Category  -->
        <!-- <template #action>
          <div type="info" class="ml-2" @click="open_add_task_dialog(1, {})">
            <el-icon class="el-icon-plus"></el-icon>
            {{ localization("Add Task") }}
          </div>
        </template> -->
      </server-table>
      <!-- End Server Table For All Categories Mobile Screen  -->
    </card>

    <!-- Start Add Or Edit New Task -->
    <el-dialog
      :title="type == 1 ? 'Add Task' : 'Edit Task'"
      :visible.sync="add_category_dialog"
      width="50%"
      top="4vh"
    >
      <el-form :model="add_task_data" :rules="rules" ref="addTeam">
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input
            :placeholder="`${localization('Write Name')}`"
            v-model="add_task_data.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Description')}`"
          prop="description"
        >
          <el-input
            :placeholder="`${localization('Write description')}`"
            v-model="add_task_data.description"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="`${localization('Topic')}`"
          prop="topic_id"
          class="w-100 mr-lg-3"
        >
          <el-select
            v-model="add_task_data.topic_id"
            :placeholder="`${localization('Please select a topic')}`"
            style="width: 100%"
          >
            <el-option
              v-for="item in categoryDetailsTopics"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button @click="close_add_task_dialog" class="text-center d-block">{{
          localization("Cancel")
        }}</el-button>
        <el-button
          type="submit"
          @click="addNewTask"
          class="text-center d-block text-capitalize"
          >{{ localization("Save") }}</el-button
        >
      </div>
    </el-dialog>
    <!--End Add Or Edit New Task -->

    <!-- // Start Show  Task -->
    <el-dialog
      :title="`${localization('Task Details')}`"
      width="60%"
      :visible.sync="show_every_task_details"
      top="4vh"
      class="add_attributes_dialog_wrapper show_statement_details"
      :close-on-click-modal="false"
    >
      <div v-if="every_task_details">
        <div class="pick_state">
          <h4 class="pick_title mt-0">{{ localization("Name") }}</h4>
          <p class="pick_text">{{ every_task_details.name }}</p>
        </div>
        <div
          class="pick_state"
          style="border-top:1px solid #ccc; padding-top:10px"
        >
          <h4 class="pick_title mt-0">{{ localization("Topic") }}</h4>
          <p
            class="pick_text"
            style=" white-space: normal;word-break: break-word;"
          >
            {{ every_task_details.topic_name }}
          </p>
        </div>
        <div
          class="pick_state"
          style="border-top:1px solid #ccc; padding-top:10px"
        >
          <h4 class="pick_title mt-0">{{ localization("Description") }}</h4>
          <p
            class="pick_text"
            style=" white-space: normal;word-break: break-word;"
          >
            {{ every_task_details.description }}
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- // End Show  Task -->
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      filter: {},
      type: null,
      add_category_dialog: false,
      add_task_data: {
        category_id: this.$route.params.id,
        name: null,
        description: null,
        topic_id: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      every_task_details: {},
      show_every_task_details: false
    };
  },
  components: {
    Card,
    ServerTable,
    LTable
  },
  mounted() {
    this.get_category_details();
  },
  computed: {
    categoryDetailsTasks() {
      return this.$store.getters["attributes/getCategoryDetailsTasks"];
    },
    categoryDetailsTopics() {
      return this.$store.getters["attributes/getCategoryDetailsTopics"];
    },
    categoryDetailsCount() {
      return this.$store.getters["attributes/getCategoryDetailsCount"];
    },
    categoryForTaskName() {
      return this.$store.getters["attributes/getCategoryForTaskName"];
    }
  },

  methods: {
    get_category_details(query) {
      this.$store.dispatch("attributes/everyCategoryDetails", {
        query: query,
        id: this.$route.params.id
      });
    },
    // open add Task Category dialog
    open_add_task_dialog(type, item) {
      this.add_category_dialog = !this.add_category_dialog;
      this.add_task_data = item;
      this.type = type;
    },
    // close add Task Category dialog
    close_add_task_dialog() {
      this.get_category_details();
      this.add_category_dialog = false;
      this.add_task_data = {};
      this.type = null;
    },
    addNewTask() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("attributes/addNewTask", {
                category_id: this.$route.params.id,
                name: this.add_task_data.name,
                description: this.add_task_data.description,
                topic_id: this.add_task_data.topic_id
              })
              .then(() => {
                this.add_category_dialog = !this.add_category_dialog;
                this.get_category_details();
                this.add_task_data = this.add_task_data;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Task has been added",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false,
                  confirmButtonText: "ok"
                });
              });
          } else {
            this.$store
              .dispatch("attributes/addNewTask", {
                id: this.add_task_data.id,
                category_id: this.$route.params.id,
                name: this.add_task_data.name,
                description: this.add_task_data.description,
                topic_id: this.add_task_data.topic_id
              })
              .then(() => {
                this.add_category_dialog = !this.add_category_dialog;
                this.get_category_details();
                this.add_task_data = this.add_task_data;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Task has been updated",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false,
                  confirmButtonText: "ok"
                });
              });
          }
        }
      });
    },
    deleteTask(id) {
      Swal.fire({
        title: "",
        text: `Are you sure to delete this Task?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Delete",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("attributes/deleteTask", id).then(_ => {
            this.get_category_details(null);
            Swal.fire({
              title: "",
              text: "Task has been deleted.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },
    getTaskDetails(item) {
      this.every_task_details = item;
      this.show_every_task_details = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
.name_wrapper_task {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  .part_1 {
    color: #11bfe3;
  }
  .part_2 {
    color: #777;
  }
}
</style>

<style lang="scss">
.show_statement_details {
  .el-dialog__close {
    display: block !important;
    cursor: pointer;
  }
}
.team_category_wrapper {
  .actions_wrapper {
    display: flex;
    gap: 8px;
    justify-content: end;
    padding: 5px 0;
    .actions-icons {
      font-size: 14px;
      width: 30px;
      height: 30px;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #777;
      transition: 0.3s;

      &.add {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }

      &.edit {
        &:hover {
          background-color: #ffa534;
          color: #fff;
        }
      }

      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
}

.category_for_large {
  display: block !important;
  @media (min-width: 320px) and (max-width: 776px) {
    display: none !important;
  }
}
.category_for_mobile {
  display: none !important;
  @media (min-width: 320px) and (max-width: 776px) {
    display: block !important;
  }
}
// ::v-deep .el-table_1_column_2 {
//   @media (min-width: 320px) and (max-width: 776px) {
//     display: none !important;
//   }
// }
</style>
